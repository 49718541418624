import { Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const BreadCrumbs = () => {
  const location = useLocation();
  const paths = useMemo(() => {
    let temp = location.pathname.split('/');
    temp = temp.filter((item) => item !== '' && item !== '/');
    let lastLink = '';
    const links = temp.map((item) => {
      lastLink += `/${item}`;
      return { value: lastLink, label: item };
    });
    return links;
  }, [location.pathname]);

  const navigate = useNavigate();

  return (
    <Stack direction="row" alignItems="center">
      {paths.map((item, index) => {
        if (index < paths.length - 1) {
          return (
            <>
              <Typography
                variant="body1"
                onClick={() => navigate(item.value)}
                sx={{
                  cursor: 'pointer',
                  color: 'grey.600',
                  '&:hover': {
                    textDecoration: 'underline',
                    color: 'text.primary',
                  },
                }}
              >
                {item.label}
              </Typography>
              <Typography
                variant="body1"
                sx={{ mx: 0.75, color: 'text.disabled' }}
              >
                /
              </Typography>
            </>
          );
        }
        return <Typography variant="body1">{item.label}</Typography>;
      })}
    </Stack>
  );
};

export default BreadCrumbs;
