/* eslint-disable react/prop-types */
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';

import logo from 'assets/images/logo.png';
import { HEADER_SIZE } from 'constants/layout';
import { toggleMobileNav } from 'features/app/appSlice';
import { logout } from 'features/auth/userSlice';
import { usePermissions } from 'hooks/usePermissions';
import { useEffect, useState } from 'react';
import { FiPower } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { asidePages, noAccessReq, publicPages } from 'routes/menu';
import { filterAsidePages } from 'utils/checkPermissions';
import MultiLevelMenu, { listItemStyles } from './components/MultiLevelMenu';
import SingleLevelMenu from './components/SingleLevelMenu';

export const customIconsStyle = { fontSize: '1.2rem' };

const AsideMobileWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  top: 0,
  left: 0,
  bottom: 0,
  width: 250,
  height: '100vh',
  background: theme.palette.primary.main,

  [theme.breakpoints.up('md')]: {},
}));

const AsideWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  top: 0,
  left: 0,
  bottom: 0,
  width: '250px',
  height: '100vh',
  background: theme.palette.primary.main,

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const AsideHeaderWrapper = styled(Box)(({ theme }) => ({
  height: HEADER_SIZE,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: theme.palette.success.light,
}));

const AsideBodyWrapper = styled(Box)(() => ({
  width: '100%',
  marginTop: HEADER_SIZE,
  position: 'absolute',
  left: 0,
  top: 0,
  bottom: 0,
}));

const AsideListWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'absolute',
  left: 0,
  top: 0,
  height: '100%',
  overflowY: 'scroll',
  overflowX: 'hidden',
  flex: '1.1',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all .2s',
  backgroundColor: theme.palette.primary.main,

  // scrollbar
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: '0.4em 0.5em',
  paddingLeft: '1em',
  borderRadius: '3px',
  color: theme.palette.secondary.dark,
  margin: '0',
  transition: 'all .2s',

  '& > *': {
    fontWeight: 500,
    color: theme.palette.secondary.main,
  },
  '&:hover': {
    background: theme.palette.secondary.dark,
  },
  '&:hover > *': {
    color: theme.palette.secondary.light,
  },
  '&.active': {
    background: theme.palette.secondary.dark,
  },
  '&.active > *': {
    color: theme.palette.secondary.light,
  },
}));

export const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  fontSize: '1rem',
  minWidth: '2.5rem',
  paddingRight: '0',
  marginRight: '0',
  color: theme.palette.secondary.main,
}));

export const ImgLogo = styled('img')({
  height: 32,
  width: 42,
  '&:hover': {
    cursor: 'pointer',
  },
});

const MenuItem = ({ item }) => {
  const { items, ...rootItem } = item;
  if (items !== undefined && item.items.constructor === Object) {
    // check if dropdown contains items, if not render nothing
    if (Object.values(items).length > 0) {
      return <MultiLevelMenu item={{ ...rootItem, items }} />;
    }
    return null;
  }
  return <SingleLevelMenu item={item} />;
};

const MenuList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const COMPANY_NAME = 'Qbetpro Admin';
  const [filteredMenu, setFilteredMenu] = useState({});
  const permissions = usePermissions();

  useEffect(() => {
    const filtered = filterAsidePages(permissions, asidePages);

    setFilteredMenu(filtered);

    const t = setTimeout(() => {
      if (
        permissions?.length === 0
        && !noAccessReq.includes(location.pathname)
      ) {
        navigate('/no-permission');
      }
    }, 500);
    return () => clearTimeout(t);
  }, [permissions, location.pathname, navigate]);

  const menuListItems = Object.values(filteredMenu);

  const handleLogout = () => {
    dispatch(logout({}));
    navigate(publicPages.login.path, { replace: true });
  };

  return (
    <>
      <AsideHeaderWrapper>
        <ImgLogo src={logo} alt="Company logo" sx={{ ml: 2 }} />
        <Typography variant="h6" fontWeight={600} fontSize={14} sx={{ px: 1 }}>
          {COMPANY_NAME}
        </Typography>
      </AsideHeaderWrapper>
      <AsideBodyWrapper>
        <AsideListWrapper>
          <List
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              width: '100%',
              height: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                padding: '0em 0.5em',
              }}
            >
              <Box style={{ width: '100%', height: '100%' }} component="div">
                {menuListItems.map((menuListItem) => {
                  return <MenuItem item={menuListItem} key={menuListItem.id} />;
                })}

                <ListItem disablePadding>
                  <StyledListItemButton onClick={handleLogout}>
                    <StyledListItemIcon>
                      <FiPower />
                    </StyledListItemIcon>
                    <ListItemText
                      primaryTypographyProps={listItemStyles}
                      primary="Logout"
                    />
                  </StyledListItemButton>
                </ListItem>
              </Box>
            </Box>
          </List>
        </AsideListWrapper>
      </AsideBodyWrapper>
    </>
  );
};

const Aside = () => {
  const mobileNav = useSelector((state) => state.app.mobileNav);
  const dispatch = useDispatch();

  return (
    <>
      <Drawer
        anchor="left"
        open={mobileNav}
        onClose={() => dispatch(toggleMobileNav({}))}
        sx={{ display: { sm: 'block', md: 'none' } }}
      >
        <AsideMobileWrapper
          role="presentation"
          sx={{
            width: 250,
          }}
          isMobileNav={mobileNav}
        >
          <MenuList />
        </AsideMobileWrapper>
      </Drawer>
      <AsideWrapper isMobileNav={mobileNav}>
        <MenuList />
      </AsideWrapper>
    </>
  );
};

export default Aside;
