/* eslint-disable react/prop-types */
import { ListItem, ListItemText } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toggleMobileNav } from 'features/app/appSlice';
import { listItemStyles } from './MultiLevelMenu';
import { StyledListItemButton, StyledListItemIcon } from '../Aside';

const SingleLevelMenu = ({ item }) => {
  const dispatch = useDispatch();
  const isMobileNav = useSelector((state) => state.app.mobileNav);

  return (
    <ListItem disablePadding>
      <StyledListItemButton
        component={NavLink}
        activeClassName="active"
        to={item.path}
        onClick={() => isMobileNav && dispatch(toggleMobileNav())}
      >
        <StyledListItemIcon>{item.icon}</StyledListItemIcon>
        <ListItemText
          primaryTypographyProps={listItemStyles}
          color="red"
          primary={item.text}
        />
      </StyledListItemButton>
    </ListItem>
  );
};

export default SingleLevelMenu;
