/* eslint-disable indent */
export const initialModuleAccess = {
  read: false,
  create: false,
  update: false,
  delete: false,
};

export const getViewableModules = (perms, access) => {
  return perms
    ? perms
      .filter((perm) => perm.permissions.includes(access))
      .map((perm) => perm.module_type)
    : [];
};

export const getModuleAccess = (perms, moduleType) => {
  const moduleAccess = { ...initialModuleAccess };

  const filtered = perms.filter((perm) => perm.module_type === moduleType);
  if (filtered.length === 0) return moduleAccess;

  filtered[0].permissions.forEach((access) => {
    switch (access) {
      case 'read':
        moduleAccess.read = true;
        break;
      case 'create':
        moduleAccess.create = true;
        break;
      case 'update':
        moduleAccess.update = true;
        break;
      case 'delete':
        moduleAccess.delete = true;
        break;
      default:
        break;
    }
  });

  return moduleAccess;
};
