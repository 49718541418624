import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { FC } from 'react';
import BreadCrumbs from './BreadCrumbs';
import Header from './Header';

const StyledHeader = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingRight: '1.5em',
  paddingLeft: '1.5em',
}));

interface Props {
  title: string;
}

const HeaderBottom: FC<Props> = ({ title }) => {
  return (
    <StyledHeader sx={{ pt: 2, pb: 0.5, color: 'secondary.next' }}>
      <Stack spacing={0.5}>
        <BreadCrumbs />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h6"
            fontWeight={700}
            sx={{ alignSelf: 'flex-end' }}
            color="secondary.next"
          >
            {title}
          </Typography>
        </Box>
      </Stack>
    </StyledHeader>
  );
};

const DefaultHeader: FC<Props> = ({ title }) => {
  return <Header headerBottom={<HeaderBottom title={title} />} />;
};

export default DefaultHeader;
