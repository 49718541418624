import { ThemeOptions } from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#11235A',
    },
    secondary: {
      dark: '#f7f7f7',
      main: '#ced6f0',
      light: '#11235A',
    },
    background: {
      default: '#f7f7f7',
    },
  },
};
