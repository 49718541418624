/* eslint-disable no-param-reassign */
import { getViewableModules } from 'utils/permissionUtils';
import { noAccessReq } from 'routes/menu';

export const checkRoutePermitted = (pages, path, permitted = false) => {
  pages.every(({ items, ...item }) => {
    if (items) {
      permitted = checkRoutePermitted(Object.values(items), path, permitted);
    } else if (item.path === path || noAccessReq.includes(path)) {
      permitted = true;
    }
    if (permitted) return false;
    return true;
  });

  return noAccessReq.includes(path) ? true : permitted;
};

export const filterAsidePages = (permissions, pages) => {
  const modules = getViewableModules(permissions, 'read');
  const values = Object.values(pages);
  const filteredPages = {};
  values.forEach(({ items, ...item }) => {
    if (modules.includes(item.module) || item.dropdown) {
      filteredPages[item.id] = item;
      if (items) {
        filteredPages[item.id].items = filterAsidePages(permissions, items);
      }
    }
  });
  return filteredPages;
};
