import { Box, useTheme } from '@mui/material';
import { FC, ReactNode } from 'react';
import HeaderTop from './HeaderTop';

interface Props {
  headerTop?: ReactNode;
  headerBottom?: ReactNode;
}

const Header: FC<Props> = ({ headerTop, headerBottom }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row-reverse' },
        justifyContent: 'space-between',
        alignItems: { xs: '', md: 'center' },
        width: '100%',
        boxShadow: {
          sm: `0px 0px 0px 0px ${theme.palette.primary.main}bf`,
          md: `0px -8px 14px -2px ${theme.palette.primary.main}bf`,
        },
      }}
    >
      {headerTop}
      {headerBottom}
    </Box>
  );
};

Header.defaultProps = {
  headerTop: <HeaderTop />,
  headerBottom: null,
};
export default Header;
