import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Divider,
  Icon,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { HEADER_SIZE } from 'constants/layout';
import { toggleMobileNav } from 'features/app/appSlice';
import { logout } from 'features/auth/userSlice';
import { AiOutlineUser } from 'react-icons/ai';
import { FiPower, FiUser } from 'react-icons/fi';
import { HiOutlineMenu } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { commonPages, publicPages } from 'routes/menu';

export const HeaderTopWrapper = styled(Paper)(({ theme }) => ({
  height: HEADER_SIZE,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: theme.palette.background.default,
  borderRadius: 0,

  [theme.breakpoints.down('md')]: {
    boxShadow: `0px -8px 22px -2px ${theme.palette.primary.main}bf`,
  },
}));

export const StyledMenuItemIcon = styled(ListItemIcon)(({ theme }) => ({
  fontSize: '1rem',
  minWidth: '2.5rem',
  color: theme.palette.primary.main,
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.primary.main,
}));

export const HeaderTopLeft = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
}));

export const StyledMenuIcon = styled(HiOutlineMenu)(({ theme }) => ({
  display: 'none',
  fontSize: '2rem',
  color: theme.palette.primary.main,

  '&:hover': {
    cursor: 'pointer',
  },
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}));

export const HeaderTopRight = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  justifyContent: 'end',
  paddingRight: '1em',
}));

const UserComp = () => {
  const username = useSelector((state) => state.user.username);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout({}));
    navigate(publicPages.login.path, { replace: true });
  };

  return (
    <>
      <div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <StyledMenuItem
            onClick={handleClose}
            component={Link}
            to={commonPages.profile.path}
            sx={{ fontWeight: 500 }}
          >
            <StyledMenuItemIcon>
              <AiOutlineUser />
            </StyledMenuItemIcon>
            Profile
          </StyledMenuItem>
          <StyledMenuItem onClick={handleLogout}>
            <StyledMenuItemIcon>
              <FiPower />
            </StyledMenuItemIcon>
            Logout
          </StyledMenuItem>
        </Menu>
      </div>
      <Box
        // component={Link}
        // to={InnerPages.Profile.path}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          cursor: 'pointer',
          textDecoration: 'none',
        }}
      >
        <Typography variant="h6" fontWeight={600} fontSize={14}>
          {username}
        </Typography>
        <Box
          component="div"
          sx={{
            color: 'primary.main',
            display: 'flex',
            alignItems: 'center',
            ml: 0.7,
          }}
        >
          <Icon sx={{ fontSize: '16px', color: 'text.primary' }}>
            <FiUser />
          </Icon>
          <Divider
            color="#fff"
            textAlign="center"
            flexItem
            sx={{ fontSize: '16px' }}
          />
          <KeyboardArrowDownIcon
            sx={{ fontSize: '16px', color: 'text.primary' }}
          />
        </Box>
      </Box>
    </>
  );
};

const HeaderTop = () => {
  const dispatch = useDispatch();
  const isMobileNav = useSelector((state) => state.app.mobileNav);

  // const { data: notifications, rowCount } = useGetPaginatedData(
  //   useGetNotificationsUnreadQuery,
  //   {
  //     pagination: { pageIndex: 0, pageSize: 5 },
  //     searchValue: null,
  //     filters: {
  //       order_by: 'date_created',
  //       order_by_clause: 'DESC',
  //       status: 'NOT_OPENED',
  //     },
  //   }
  // );

  return (
    <HeaderTopWrapper elevation={0}>
      <HeaderTopLeft>
        {!isMobileNav && (
          <StyledMenuIcon onClick={() => dispatch(toggleMobileNav({}))} />
        )}
      </HeaderTopLeft>
      <HeaderTopRight>
        {/* <NotificationSnippit
          data={notifications}
          notificationsPath={commonPages.Notifications.path}
        >
          <NotificationIndicator count={notificationsCount} />
        </NotificationSnippit> */}

        <UserComp />
      </HeaderTopRight>
    </HeaderTopWrapper>
  );
};

export default HeaderTop;
