import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { asidePages } from 'routes/menu';
import { filterAsidePages } from 'utils/checkPermissions';

export const useFilteredMenu = (permissions) => {
  const isAuth = useSelector((state) => state.user.isAuth);
  const [filteredMenu, setFilteredMenu] = useState({});

  useEffect(() => {
    setFilteredMenu(filterAsidePages(permissions, asidePages));
  }, [permissions, isAuth]);

  return filteredMenu;
};
