import { Suspense } from 'react';
import PageLoadingIndicator from 'components/widgets/PageLoadingIndicator';
import ContentRoutes from './ContentRoutes';

const Content = () => {
  return (
    <Suspense fallback={<PageLoadingIndicator />}>
      <ContentRoutes />
    </Suspense>
  );
};

export default Content;
