import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { setPageLoading } from 'features/app/appSlice';
import { useFilteredMenu } from 'hooks/useFilteredMenu';
import { usePermissions } from 'hooks/usePermissions';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { protectedRoutes, publicRoutes } from 'routes/contentRoutes';
import { checkRoutePermitted } from 'utils/checkPermissions';
import { redirectRoot } from 'utils/redirect';
import ProtectedRoutes from './ProtectedRoutes';

const ContentWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  overflowY: 'scroll',
  marginTop: '4.5rem',
  paddingTop: '1.5em',

  [theme.breakpoints.down('md')]: {
    marginTop: '9.5rem',
    paddingTop: '0',
  },
}));

const ContentRoutes = () => {
  const isAuth = useSelector((state) => state.user.isAuth);
  const dispatch = useDispatch();

  const permissions = usePermissions();
  const filteredMenu = useFilteredMenu(permissions);
  const menuListItems = Object.values(filteredMenu);

  useEffect(() => {
    dispatch(setPageLoading(true));
    const t = setTimeout(() => dispatch(setPageLoading(false)), 1000);
    return () => clearTimeout(t);
  }, [isAuth, dispatch]);

  const redirectPath = useMemo(() => {
    return redirectRoot(permissions);
  }, [permissions]);

  return (
    <Routes>
      {publicRoutes.map((item) => (
        <Route key={item.path} {...item} />
      ))}
      <Route element={<ProtectedRoutes />}>
        {protectedRoutes.map(({ element, ...content }) => {
          if (checkRoutePermitted(menuListItems, content.path)) {
            return (
              <Route
                key={content.path}
                {...content}
                element={<ContentWrapper>{element}</ContentWrapper>}
              />
            );
          }
          return (
            <Route
              key={content.path}
              path={content.path}
              element={<div>Not allowed</div>}
            />
          );
        })}
        {/* todo: replace the "/" redirect with first allowed module */}
        <Route path="/" element={<Navigate to={redirectPath} replace />} />
        <Route path="*" element={<Navigate to="/not-found" replace />} />
      </Route>
    </Routes>
  );
};

export default ContentRoutes;
