/* eslint-disable react/prop-types */
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { Collapse, List, ListItem, ListItemText } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { NavLink } from 'react-router-dom';
import { toggleMobileNav } from 'features/app/appSlice';
import { StyledListItemButton, StyledListItemIcon } from '../Aside';

export const listItemStyles = { fontSize: '0.8rem', fontWeight: 600 };

const MultiLevelMenu = ({ item }) => {
  const dispatch = useDispatch();
  const isMobileNav = useSelector((state) => state.app.mobileNav);

  const { items: children } = item;
  const items = Object.values(children);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <ListItem disablePadding>
        <StyledListItemButton onClick={handleClick}>
          <StyledListItemIcon>{item.icon}</StyledListItemIcon>
          <ListItemText
            primaryTypographyProps={listItemStyles}
            primary={item.text}
          />
          {open ? (
            <ExpandLess style={{ fontSize: '18px' }} />
          ) : (
            <ExpandMore style={{ fontSize: '18px' }} />
          )}
        </StyledListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map((child) => (
            <ListItem key={child.id} disablePadding sx={{ paddingLeft: 2 }}>
              <StyledListItemButton
                component={NavLink}
                activeClassName="active"
                to={child.path}
                onClick={() => isMobileNav && dispatch(toggleMobileNav({}))}
              >
                <StyledListItemIcon>{child.icon}</StyledListItemIcon>
                <ListItemText
                  primaryTypographyProps={listItemStyles}
                  primary={child.text}
                />
              </StyledListItemButton>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default MultiLevelMenu;
