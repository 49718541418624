import { setPageLoading } from 'features/app/appSlice';
import { useFilteredMenu } from 'hooks/useFilteredMenu';
import { usePermissions } from 'hooks/usePermissions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { asideHeaders } from 'routes/headerRoutes';
import { checkRoutePermitted } from 'utils/checkPermissions';

const ContentRoutes = () => {
  const isAuth = useSelector((state) => state.user.isAuth);
  const dispatch = useDispatch();

  const permissions = usePermissions();
  const filteredMenu = useFilteredMenu(permissions);
  const menuListItems = Object.values(filteredMenu);

  useEffect(() => {
    dispatch(setPageLoading(true));
    const t = setTimeout(() => dispatch(setPageLoading(false)), 1000);
    return () => clearTimeout(t);
  }, [isAuth, dispatch]);

  return (
    <Routes>
      {asideHeaders.map((header) => {
        if (checkRoutePermitted(menuListItems, header.path)) {
          return <Route key={header.path} {...header} />;
        }
        return (
          <Route path={header.path} key={header.path} element={null} exact />
        );
      })}
    </Routes>
  );
};

export default ContentRoutes;
