import { Box, CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ModalProvider from 'components/CustomModal/ModalProvider';
import Aside from 'components/layout/Aside/Aside';
import Wrapper from 'components/layout/Wrapper';
import { Toaster } from 'components/Toast';
import { FC, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { noAsidePages } from 'routes/menu';
import { themeOptions as lightTheme } from '../constants/lightTheme';

const App: FC = () => {
  const theme = useMemo(() => createTheme(lightTheme), []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ModalProvider>
        <Box sx={{ display: 'flex', height: '100vh', width: '100%' }}>
          <Routes>
            {noAsidePages.map((path) => (
              <Route key={path} path={path} />
            ))}
            <Route path="*" element={<Aside />} />
          </Routes>
          <Wrapper />
        </Box>
      </ModalProvider>
      <Toaster position="bottom-left" />
    </ThemeProvider>
  );
};

export default App;
