import { MODULES } from 'constants/modules';
import { FaUsers } from 'react-icons/fa6';
import {
  MdDashboard,
  MdCorporateFare,
  MdSecurity,
  MdSubtitles,
} from 'react-icons/md';
import { AsidePagesType, PagesType } from './types';

export const asidePages: AsidePagesType = {
  dashboard: {
    id: 'dashboard',
    module: MODULES.dashboard,
    text: 'Dashboard',
    path: '/dashboard',
    icon: <MdDashboard />,
  },
  tenants: {
    id: 'tenants',
    module: MODULES.tenant,
    text: 'Operators',
    path: '/operators',
    icon: <MdCorporateFare />,
  },
  users: {
    id: 'users',
    module: MODULES.user,
    text: 'Users',
    path: '/users',
    icon: <FaUsers />,
  },
  roles: {
    id: 'roles',
    module: MODULES.role,
    text: 'Roles',
    path: '/roles',
    icon: <MdSubtitles />,
  },
  permissions: {
    id: 'permissions',
    module: MODULES.permission,
    text: 'Permissions',
    path: '/permissions',
    icon: <MdSecurity />,
  },
};
export const innerPages: PagesType = {};
export const commonPages: PagesType = {
  profile: {
    id: 'profile',
    text: 'Profile',
    path: '/profile',
  },
};

export const publicPages: PagesType = {
  register: {
    id: 'register',
    text: 'Register',
    path: '/auth/register',
  },
  login: {
    id: 'login',
    text: 'Login',
    path: '/auth/login',
  },
};

export const page404: PagesType = {};

// Apply route guarding for inner pages too
export const noAccessReq = [
  ...Object.values(commonPages).map((item) => item.path),
  ...Object.values(innerPages).map((item) => item.path),
];

export const noAsidePages = [
  ...Object.values(publicPages).map((item) => item.path),
];
